import React, { useState, forwardRef, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import moment from 'moment/dist/moment';
import 'moment/dist/locale/id';
import 'moment/dist/locale/my';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

//
import DashboardHeader from './header';

import Image from '../../components/Image';
import palette from '../../theme/palette';

import LoadingScreen from '../../components/LoadingScreen';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { setGameCategories } from '../../redux/slices/game';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import NotificationPopover from './NotificationPopover';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: '1rem',
  height: 'calc(100% - 1.1rem)',
  backgroundColor: '#f9f9f9',
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.only('xs')]: {
    paddingTop: HEADER.MOBILE_HEIGHT,
  },
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 8,
    // paddingRight: 8,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
}));

const BottomNav = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  flexDirection: 'column',
}));

const BottomNavIcon = styled(Image)(({ theme }) => ({
  position: 'relative',
  width: '.62rem',
  height: '.62rem',
  margin: '0 auto',
}));

const BottomNavText = styled(Typography)(({ theme }) => ({
  fontSize: '.2rem',
  color: '#8d99ae',
  textAlign: 'center',
  lineHeight: 1,
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { translate, onChangeLang, currentLang } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  useEffect(() => {
    setDefaultLang();

    moment.lang(currentLang?.value || 'en');

    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getAllGames(), getAngPauPromotion()];
    // if (isAuthenticated) promises.push(getUserMemberBalance());

    dispatch(openLoadingScreen());
    Promise.all(promises).finally(() => dispatch(closeLoadingScreen()));
  }, []);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    try {
      if (siteSettings?.config_analytics) {
        const _ga = siteSettings?.config_analytics?.split(',');

        // Multiple products (previously known as trackers)
        ReactGA.initialize(
          _ga.map((item) => ({
            // measurement ID
            trackingId: item,
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          }))
          //   [
          //   {
          //     // measurement ID
          //     trackingId: _ga[0],
          //     // gaOptions: {...}, // optional
          //     // gtagOptions: {...}, // optional
          //   },
          // ]
        );
      }

      // if (siteSettings?.config_livechat) {
      //   const respondIo = JSON.parse(siteSettings?.config_livechat);
      //   const script = document.createElement('script');

      //   script.id = respondIo?.id;
      //   script.src = respondIo?.src;
      //   script.async = true;

      //   document.head.appendChild(script);
      // }

      if (siteSettings?.config_fb_pixel) {
        const metaPixel = siteSettings.config_fb_pixel.replace(/[^\d,]+/g, '').split(',');
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      }
    } catch (error) {
      console.warn(`3RD PARTY INTEGRATION ERROR: ${error?.message}`);
    }
  }, [siteSettings]);

  const setDefaultLang = () => {
    console.log(`localCode = ${localStorage.getItem('localCode')}`);

    if (localStorage.getItem('localCode')) {
      onChangeLang(localStorage.getItem('localCode'));
      localStorage.removeItem('localCode');
      window.location.reload();
    }
  };

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {/* {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )} */}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        // backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
      />

      {/* <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}

      <MainStyle collapseClick={collapseClick}>
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />

        {/* {isDesktop && <Footer />} */}
      </MainStyle>

      <Box
        sx={{
          width: '100%',
          height: '1.1rem',
          backgroundColor: palette.bottomMenu.bgColor,
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 20,
          boxShadow: '0 -.02rem .15rem rgba(0,0,0,.1)',
          display: 'block',
          right: 0,
        }}
      >
        <Grid container sx={{ height: '100%', alignItems: 'center' }}>
          <BottomNav onClick={() => navigate('/')} item xs={12 / 5}>
            <BottomNavIcon
              src={
                location.pathname.indexOf('home') !== -1
                  ? require('../../assets/menu/bottom/footer-home-active.png')
                  : require('../../assets/menu/bottom/footer-home-inactive.png')
              }
            />
            <BottomNavText>{translate('home')}</BottomNavText>
          </BottomNav>

          <BottomNav onClick={() => navigate('/promotion')} item xs={12 / 5}>
            <BottomNavIcon
              src={
                location.pathname.indexOf('promotion') !== -1
                  ? require('../../assets/menu/bottom/footer-promo-active.png')
                  : require('../../assets/menu/bottom/footer-promo-inactive.png')
              }
            />
            <BottomNavText>{translate('promotion')}</BottomNavText>
          </BottomNav>

          <BottomNav onClick={() => navigate(isAuthenticated ? 'transaction/deposit' : '/auth/login')} item xs={12 / 5}>
            <BottomNavIcon src={require('../../assets/menu/bottom/footer-deposit-inactive.png')} />
            <BottomNavText>{translate('deposit')}</BottomNavText>
          </BottomNav>

          <NotificationPopover />

          <BottomNav onClick={() => navigate(isAuthenticated ? 'user/account' : '/auth/login')} item xs={12 / 5}>
            <BottomNavIcon src={require('../../assets/menu/bottom/footer-profile-inactive.png')} />
            <BottomNavText>{translate('member')}</BottomNavText>
          </BottomNav>
        </Grid>
      </Box>

      {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={() => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DashboardLayout;
